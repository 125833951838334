import { type IUploadDialog, type IAlertDialog, type IConfirmDialog, type ILogoutDialog, type ISnackbar } from "../interfaces/index";

export const uploadDialogTemplate = (dialog: IUploadDialog) => `<div class="avv_dialog--upload">${(dialog.uploadTitle === null ? "" : `<h2 class="title">${dialog.uploadTitle}</h2>`)}<p class="message">${dialog.uploadDescription}</p><button type="button" class="upload" aria-label="Upload a file" tabindex="1"><p><i class="material-icons" aria-hidden="true">cloud_upload</i><span>Drag and drop a file or click here to upload.</span></p></button><button type="button" class="avv-button secondary cancel">Cancel</button</div>`
export const alertDialogTemplate = (dialog: IAlertDialog) => `<div class="avv_dialog--alert">${(dialog.alertTitle === null ? "" : `<h2 class="title">${dialog.alertTitle}</h2>`)}<p class="message">${dialog.alertMessage}</p>${(dialog.alertButton === null ? "" : `<button type="button" class="avv-button">${dialog.alertButton}</button>`)}</div>`
export const logoutDialogTemplate = (dialog: ILogoutDialog) => `<div class="avv_dialog--logout">${`<h2 class="title">${localizeText('logout_notifier.header')}</h2>`}<p class="message">${localizeText('logout_notifier.content_html', {inactiveFor: LogoutStore.state.logoutAfter, logoutAt: new Date(Date.now() + ((LogoutStore.state.logoutAfter * 1000 * 60) - (LogoutStore.state.notifyBefore * 1000 * 60))).toLocaleTimeString(navigator.language, {hour: '2-digit',minute:'2-digit'})})}</p>${(LogoutStore.state.links.refresh + LogoutStore.state.links.logout)}</div>`
export const snackbarDialogTemplate = (dialog: ISnackbar) => `<div class="avv_dialog--snackbar"><p role="status" class="snack-message">${dialog.snackMessage}</p></div>`

export const confirmDialogTemplate = (dialog: IConfirmDialog) => {
  const dynClasses = {
    warnTitle: dialog.warn ? 'avv-danger-color' : '',
    message: dialog.squareDisplay ? 'text-center my-5' : '',
    warnButton: dialog.warn ? 'danger' : '',
    square: dialog.squareDisplay ? 'square flex flex-col items-center bg-white p-12 max-w-[50%] lg:max-w-[33%] rounded-md' : '',
    textCenter: dialog.squareDisplay ? 'text-center' : ''
  }

  const dynTexts = {
    title: dialog.confirmTitle || localizeText('general.confirm_action'),
    confirm: dialog.okButtonText ? dialog.okButtonText : 'OK',
    cancel: dialog.cancelButtonText ? dialog.cancelButtonText : localizeText('general.cancel')
  }

  const dynElements = {
    closeButton: dialog.closeButton ? `<i title="Abort the confirmation dialog" style="float:right;" class="material-icons close cursor-pointer">close</i>` : '',
    inputLabel: dialog.inputLabel ? `<div class="flex flex-col"><label>${dialog.inputLabel}</label><textarea class="avv-input" style="max-height: 80vh"></textarea></div>` : '',
  }

  return `<div class="avv_dialog--confirm ${dynClasses.square}">
            ${`<h2 class="title ${dynClasses.textCenter} ${dynClasses.warnTitle}">${dynTexts.title}${dynElements.closeButton}</h2>`}
            <p class="message ${dynClasses.message}">${dialog.confirmMessage}</p>
            ${dynElements.inputLabel}
            <div>
              <button type="button" class="avv-button ${dynClasses.warnButton} yes">${dynTexts.confirm}</button>
              <button type="button" class="avv-button secondary no">${dynTexts.cancel}</button>
            </div>
          </div>`
}
